import React from "react";
import Particles from "react-particles-js";
import Progress from "components/progress";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
import "./styles.scss";

class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section id={`${this.props.id}`} className="about" style={{ height: this.context.height }}>
        {this.particles()}
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>About Me</h4>
              </div>
              <h3>I'm a Full Stack JavaScript developer</h3>
              <div className="separator" />
              <p>
                Experienced Computer Programmer with a demonstrated history of working in the computer software
                industry. Skilled in Javascript, NodeJS, Angular, React, MongoDb, SQL. Strong engineering professional
                with a Bachelor of Science (BSc) focused in Computer Science from Lancaster University.
              </p>
              <div className="social social_icons">
                <FontAwesomeIcon
                  icon={faGithub}
                  className="social_icon"
                  onClick={() => window.open("https://www.github.com/TayyabKML")}
                />
                {/* <FontAwesomeIcon
                  icon={faTwitter}
                  className="social_icon"
                  onClick={() => window.open("https://www.twitter.com")}
                />
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="social_icon"
                  onClick={() => window.open("https://www.youtube.com")}
                /> */}
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="social_icon"
                  onClick={() => window.open("https://www.linkedin.com/in/tayyab-kamal")}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text">
              <h4>My Skills</h4>
            </div>
            <div className="skills-container">
              <Progress name="NodeJS" value={90} delay={1100} />
              <Progress name="Angular" value={85} delay={1100} />
              <Progress name="React" value={85} delay={1100} />
              <Progress name="React Native" value={50} delay={1100} />
              <Progress name="PostgreSQL" value={80} delay={1100} />
              <Progress name="MongoDB" value={80} delay={1100} />
              <Progress name="TypeScript" value={85} delay={1100} />
              <Progress name="HTML/CSS" value={90} delay={1100} />
            </div>
          </Col>
        </Row>
      </section>
    );
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
            },
            size: {
              value: 1,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }
}

export default Hero;
